<template>
  <span :class='classTitle' @click='openSubNav' h="browse-top-menu">
    <span>{{ list.title }}</span>
  </span>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'TabsDT',
  props: ['list', 'classTitle'],
  setup(props) {
    const store = useStore()
    const menuItemSelected = computed(() => store.state.generalStore.generalData.megaMenuInfor.itemSelected)

    const openSubNav = () => {
      if(props.list.sub_nav) {
        store.commit('generalStore/setTabIndex', 0)
        if(!menuItemSelected.value || menuItemSelected.value.title !== props.list.title) {
          const data = {
            status: true,
            itemSelected: props.list
          }
          store.commit('generalStore/setMegaMenuStatus', data)
        }
        else {
          const data = {
            status: false,
            itemSelected: {}
          }
          store.commit('generalStore/setMegaMenuStatus', data)
        }
      }
    }
    return {
      openSubNav
    }
  }
}
</script>

<style scoped>

</style>
