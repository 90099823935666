<template>
    <template v-if='!isSearchResultPage'>
      <router-link :to="{ name: 'search-result' }" v-show='showButton'
                  :class="[$device.isDesktopOrTablet ? 'button-border button-gray flex items-center mr-20 py-8 pl-8 pr-12 fs-14 lh-20 br-999 fw-500 relative search-menu-button' : 'mr-15']">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.75317 13.1343C4.78154 13.1343 2.37256 10.7253 2.37256 7.75366C2.37256 4.78203 4.78154 2.37305 7.75317 2.37305C10.7248 2.37305 13.1338 4.78203 13.1338 7.75366C13.1338 8.99706 12.712 10.142 12.0038 11.0531L15.6271 14.6764L14.6759 15.6276L11.0526 12.0043C10.1415 12.7125 8.99657 13.1343 7.75317 13.1343ZM11.7886 7.75366C11.7886 9.98238 9.9819 11.7891 7.75317 11.7891C5.52445 11.7891 3.71771 9.98238 3.71771 7.75366C3.71771 5.52494 5.52445 3.7182 7.75317 3.7182C9.9819 3.7182 11.7886 5.52494 11.7886 7.75366Z" fill="black"/>
        </svg>
        <span class='pl-8 black' v-if='$device.isDesktopOrTablet'>Search Anywhere</span>
      </router-link>
    </template>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute()
const store = useStore()

const showButton = ref(false)

const isSearchResultPage = computed(() => {
  return route.name === 'search-result'
})

const showSearchButton = () => {
  const element = document.getElementById('text-area-search')
  if(element) {
    let eBottom = element.getBoundingClientRect().bottom
    // console.log('eBottom',eBottom)
    showButton.value = (eBottom <= 0)
  }
}

onMounted(() => {
  if(route.name === 'home') {
    window.addEventListener('scroll', showSearchButton)
  } else showButton.value = true
})

onUnmounted(() => {
  if(route.name === 'home') {
    window.removeEventListener('scroll', showSearchButton)
  }
})

</script>

<style scoped lang="scss">
.search-menu-button {
  transition: display 0.25s ease-in-out;
  border-color: #E2E8F0;
}
</style>
