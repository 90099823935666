<template>
  <transition name="mega-fade">
    <div class='wrapper absolute left-0 right-0 top-130' id='mega-menu'>
      <Tabs v-if='menuItemSelected && menuItemSelected.sub_nav' :tabTitle='menuItemSelected.sub_nav.item' :heap="heapAttribute">
        <template v-slot:[i] v-for="(subList, i) in menuItemSelected.sub_nav.item" :key='i'>
          <ul class='sub-items ma-0 pa-0 flex flex-wrap flex-column box-border items-baseline'>
            <li v-for='(item, idx) in subList' :key='idx' class='fs-12 lh-14 mb-12 db hand box-border self-start'>
<!--              <a :href='goToURL(item.url)' @click='openURL(menuItemSelected.name)'><span class='gray-dark name'>{{item.name}}</span></a>-->
              <router-link :to='goToURL(item.url)' @click.native='openURL(menuItemSelected.name)'><span class='gray-dark name'>{{item.name}}</span></router-link>
            </li>
          </ul>
        </template>
      </Tabs>
    </div>
  </transition>
</template>

<script>
import Tabs from './items/Tabs'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  name: 'MegaMenu',
  components: { Tabs },
  setup() {
    const store = useStore()
    const route = useRoute()

    const menuItemSelected = computed(() => store.state.generalStore.generalData.megaMenuInfor.itemSelected)

    const heapAttribute = computed(() => {
      const selectedItem = menuItemSelected?.value?.name
      switch(selectedItem) {
        case "destinations":
          return "browse-destination-submenu"
        case "local designers":
          return "browse-local-designer-submenu"
        case "explore":
          return "browse-explore-submenu"
        default:
          return ""
        }
    })

    const goToURL = (url) => {
      const routeURL = route.path
      if(routeURL !== url) return url
      return route.fullPath
    }

    watch(
      () => route.name,
      () => {
        store.commit('generalStore/setTabIndex', 0)
        const data = {
          status: false,
          itemSelected: ''
        }
        store.commit('generalStore/setMegaMenuStatus', data)
      },
    )

    const setGallerySource = async () => {
      await store.dispatch('heapStore/changeGalleryHeap', 'header')
    }

    const openURL = (item) => {
      switch(item) {
        case "destinations":
        case "explore":
        setGallerySource();
        break;
      }

      store.commit('generalStore/setTabIndex', 0)
      const data = {
        status: false,
        itemSelected: {}
      }
      store.commit('generalStore/setMegaMenuStatus', data)
    }

    return {
      openURL, menuItemSelected, goToURL, heapAttribute
    }
  }
}
</script>

<style scoped lang='scss'>
#mega-menu {
  background-color: #F8FBFF;
  box-shadow: 0px 10px 25px rgba(164, 203, 250, 0.4);
}
.sub-items {
  max-height: 104px;
  width: 180px;
  li {
    width: 180px;
    .name:hover {font-weight: 700; color: black; font-family: 'proximanova-bold';}
  }
}
</style>
