<template>
  <Tabs :tabTitle='getTitle' :heap="heapAttribute">
    <template v-slot:[i] v-for="(subList, i) in list.sub_nav.item" :key='i'>
      <ul class='sub-items ma-0 pa-0'>
        <li v-for='(item, idx) in subList' :key='idx' class='fs-12 lh-14 mb-12 db hand'>
<!--          <a :href='goToURL(item.url)' @click='openURL'><span class='gray-dark name'>{{item.name}}</span></a>-->
          <router-link :to='goToURL(item.url)' @click='openURL'><span class='gray-dark name'>{{item.name}}</span></router-link>
        </li>
      </ul>
    </template>
  </Tabs>
</template>

<script>
import Tabs from './Tabs'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'TabsMB',
  components: { Tabs },
  props: ['list'],
  emits: ['openURL'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()

    const openURL = () => {
      emit('openURL')
      store.commit('generalStore/setTabIndex', 0)
      const data = {
        status: false,
        statusSubNav: false
      }
      store.commit('generalStore/setMegaMenuStatusMB', data)

    }

    const heapAttribute = computed(() => {
      const selectedItem = props.list.name
      switch(selectedItem) {
        case "destinations":
          return "browse-destination-submenu"
        case "local designers":
          return "browse-local-designer-submenu"
        case "explore":
          return "browse-explore-submenu"
        default:
          return ""
        }
    })

    const getTitle = computed(() => {
      return props.list.sub_nav.item
    })

    const goToURL = (url) => {
      const routeURL = route.path
      if(routeURL !== url) return url
      return route.fullPath
    }

    return {
      openURL, getTitle, goToURL, heapAttribute
    }
  }
}
</script>

<style lang='scss' scoped>
.sub-items {
  li:last-child {
    margin-bottom: 0;
  }
  .name:hover {font-weight: 700; color: black; font-family: 'proximanova-bold';}
}
</style>
