<template>
  <HeaderTraveller />
</template>

<script setup>
import HeaderTraveller from './HeaderTraveller'
import { getOperationNumber } from '~/helper'
import AutoOpenChat from '~/components/ui/chat/AutoOpenChat'
import { watchEffect, computed, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { useGetCookie } from '~/composables/common/useCustomCookie'

// ---Define---
const route = useRoute()
const store = useStore()
const router = useRouter()

//const dj_curr = process.env.DEFAULT_CURRENCY_CODE || useGetCookie('dj_curr').value
const dj_curr = useGetCookie('dj_curr').value || store.state?.currenciesStore?.currencySelected?.code
const dj_curr_symbol = useGetCookie('dj_curr_symbol').value || store.state?.generalStore?.detectCountryInfo?.currency_symbol
//const country_code = process.env.DEFAULT_COUNTRY_CODE || useGetCookie('country_code').value
const country_code = useGetCookie('country_code').value || store.state?.generalStore?.detectCountryInfo?.country_code
//console.log('----- Header -----', country_code, dj_curr)
//const country_name = process.env.DEFAULT_COUNTRY_NAME || useGetCookie('country_name').value
const country_name = useGetCookie('country_name').value || store.state?.generalStore?.detectCountryInfo?.country_name
//const region_name = process.env.DEFAULT_REGION || useGetCookie('region_name').value
const region_name = useGetCookie('region_name').value || store.state?.generalStore?.detectCountryInfo?.region
//const city_name = process.env.DEFAULT_CITY || useGetCookie('city_name').value
const city_name = useGetCookie('city_name').value || store.state?.generalStore?.detectCountryInfo?.city
const continent_code = useGetCookie('continent_code').value || store.state?.generalStore?.detectCountryInfo?.continent_code
const continent = useGetCookie('continent').value || store.state?.generalStore?.detectCountryInfo?.continent
const timezone = useGetCookie('timezone').value || store.state?.generalStore?.detectCountryInfo?.timezone

// ---Computed---
const state = computed(() => store.state.generalStore.finishedChat)
const userInfo = computed(() => store.getters['authStore/getUserInfo'])
const detectCountryInfo = computed(() => {
  return store.state.generalStore.detectCountryInfo
})

// ---Method---
const isExistDataCookie = () => {
  // return getCookie('dj_curr') && getCookie('country_code')
  return dj_curr && country_code && continent_code
}
const setPhoneNumberByCookie = () => {
  if (isExistDataCookie()) {
    store.commit('generalStore/setDetectCountryLoaded', true)
    // const countryCodeToCheck = getCookie('country_code')
    const countryCodeToCheck = country_code
    let phone = getOperationNumber(countryCodeToCheck, continent_code)
    // store.commit('generalStore/setDetectCountryInfo', {
    //   country_code: getCookie('country_code'),
    //   country_name: getCookie('country_name'),
    //   region_name: getCookie('region_name'),
    //   city_name: getCookie('city_name'),
    //   phone,
    // })
    // console.log('[setDetectCountryInfo setPhoneNumberByCookie]',phone)
    store.commit('generalStore/setDetectCountryInfo', {
      country_code,
      country_name,
      region: region_name,
      city: city_name,
      phone,
      continent,
      continent_code,
      currency_code: dj_curr,
      currency_symbol: dj_curr_symbol,
      timezone,
    })
    return
  }
}

// ---Watch---
watch(
  () => store.state.authStore.auth,
  (newValue) => {
    if (newValue.phone && isValidPhoneNumber(userInfo.value.phone)) {
      const countryCodeToCheck = parsePhoneNumber(userInfo.value.phone).country
      let phone = getOperationNumber(countryCodeToCheck)
      // console.log('[setDetectCountryInfo watch]',phone)
      store.commit('generalStore/setDetectCountryInfo', {
        ...detectCountryInfo.value,
        phone,
      })
    }
  },
  { deep: true },
)
watchEffect(() => {
  if (state.value === 'finished') {
    if (Object.keys(route.query).toString().indexOf('open_chat') !== -1) {
      let query = Object.assign({}, route.query)

      let chat_id = query.open_chat
      if (chat_id) {
        setTimeout(() => {
          AutoOpenChat(chat_id)
        }, 500)
        delete query.open_chat
      }

      router.replace({ query })
    }
  } else {
    return false
  }
})

// ---Mounted---
onMounted(() => {
  setPhoneNumberByCookie()
})
</script>

<style scoped></style>
