<template>
  <transition name="mega-fade">
    <div id="mega-menu-mb" class="absolute left-0 right-0">
      <div class="px-20">
        <div class="user flex py-30 bd-b-1 bd-blue-branding justify-between">
          <div class="item">
            <div v-if="is_verified || userInfo?.user_id" class="flex items-center hand hp-100" @click="toAccountSettings">
              <div>
                <div class="circle">
                  <span v-if="!avatar">{{ getUserName }}</span>
                  <img v-else class="img-cover" :src="avatar" />
                </div>
              </div>
              <div class="gray-dark">
                <div class="fs-12 lh-14 fw-700">
                  {{ firstName + ' ' + lastName }}
                </div>
                <div class="fs-10 lh-16">Account Settings</div>
              </div>
            </div>
            <button v-else class="button button-blue-light wp-100" @click="toLogin">Login</button>
          </div>
          <div class="item" v-if="!is_verified || !userInfo?.user_id">
            <button class="button button-blue-light wp-100" @click="toSignUp">Sign Up</button>
          </div>
          <div class="item" >
            <button v-if='!showEnquiryButton' class="button button-blue-dark wp-100" @click="toDesignTrip">Enquire</button>
          </div>
        </div>
        <div v-if="is_verified || userInfo?.user_id" class="flex items-center justify-center bd-b-1 bd-blue-branding py-20">
          <div :class="designBlockClass" class="hand" @click="toEnquiries">
            <img src="https://s3-cdn.designerjourneys.com/static/icons/my-enquiries.png" class="mb-8" height="20" />
            Enquiries
          </div>
          <div class="w-20"></div>
          <div class="hand" :class="designBlockClass" @click="redirectToBooking">
            <img src="https://s3-cdn.designerjourneys.com/static/icons/suitcase.png" class="mb-8" height="20" />
            Bookings
          </div>
        </div>
        <div v-if="domain === 'traveller'" id="sub-nav-mb-how-it-works" class="bd-b-1 bd-blue-branding py-20 parent-nav-mb" h="browse-top-menu">
          <div class="flex items-center wp-100" @click="handleSubNav(howItWorks)">
            <span class="fs-14 lh-18 fw-700 pr-10">{{ howItWorks.title }}</span>
          </div>
        </div>
        <div
          v-for="(item, index) in topMenu"
          :key="`item-${index}`"
          :id="`sub-nav-mb-${item.name}`"
          class="bd-b-1 bd-blue-branding py-20 parent-nav-mb"
          h="browse-top-menu"
        >
          <div class="flex items-center wp-100" @click="handleSubNav(item)">
            <span class="fs-14 lh-18 fw-700 pr-10">{{ item.title }}</span>
            <span v-if="item.sub_nav" class="flex items-center justify-center">
              <img class="icon-up" src="https://s3-cdn.designerjourneys.com/static/icons/chevron-up.svg" />
              <img class="icon-down" src="https://s3-cdn.designerjourneys.com/static/icons/chevron-down.svg" />
            </span>
          </div>
          <div v-show="item.sub_nav" class="sub-tab">
            <TabsMB v-if="`sub-nav-mb-${item.name}` === menuItemSelected" :list="item" @openURL="handleSubNav(item)"></TabsMB>
          </div>
        </div>
        <div v-for="(item, index) in list" :key="`item-${index}`" :id="`sub-nav-mb-${item.name}`" class="bd-b-1 bd-blue-branding py-20 parent-nav-mb">
          <div class="flex items-center wp-100" @click="handleSubNav(item)">
            <span class="fs-14 lh-18 fw-700 pr-10">{{ item.title }}</span>
          </div>
        </div>
        <div class="flex items-center flex-wrap py-20">
          <img :src="`https://s3-cdn.designerjourneys.com/static/icons/phone-call-12x12.png`" height="12" alt="Phone" />
          <a href="tel: +613 9071 0069"><span class="fw-700 pl-10 fs-14 lh-18 black">03 9071 0069</span></a>
          <img class="px-10" :src="`https://s3-cdn.designerjourneys.com/static/icons/australia.png`" height="16" alt="Australia" />
          <span class="fs-12 lh-14">Monday to Friday 8am-9pm (AEDT)</span>
        </div>
      </div>
      <div class="px-20 bg-blue-300">
        <div
          v-for="(item, index) in userNavMB"
          :key="index"
          :id="`sub-nav-mb-${item.name}`"
          class="flex items-center flex-wrap parent-nav-mb"
          :class="{
            'bd-b-1 bd-white py-20': item.title !== 'Logout' || is_verified || userInfo?.user_id,
          }"
        >
          <div class="flex items-center wp-100" @click="handleSubNav(item)">
            <span class="fs-14 lh-18 fw-700 pr-10" v-if="item.title !== 'Logout'">{{ item.title }}</span>
            <span v-if="item.sub_nav" class="flex items-center justify-center">
              <img class="icon-up" src="https://s3-cdn.designerjourneys.com/static/icons/chevron-up.svg" />
              <img class="icon-down" src="https://s3-cdn.designerjourneys.com/static/icons/chevron-down.svg" />
            </span>
            <span class="fs-14 lh-18 fw-700 pr-10 hand" @click.stop="handleLogout" v-else-if="is_verified || userInfo?.user_id">{{ item.title }}</span>
          </div>
          <div v-show="item.sub_nav" class="sub-tab">
            <DropDown v-if="`sub-nav-mb-${item.name}` === menuItemSelected" :list="item.sub_nav"></DropDown>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
// import { getCookie, setCookie } from '~/mixins/cookie'
import { useGetCookie } from '~/composables/common/useCustomCookie'
import { computed, ref, onMounted, watch } from 'vue'
import TabsMB from './items/TabsMB'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DropDown from '../MenuMobile/DropDown'
import { publicDomain } from '~/helper'
import { TfChat } from 'tf-chat'
import { pages } from '~/constant/redirectLogout'

// ---Props & Emit---
const props = defineProps({
  list: { type: [Object, Array] },
  headerType: {
    type: String,
    default: 'Traveller', // Traveller, Agent, DesignAgent
  },
  userData: {
    type: Object,
    default: () => {},
  },
})

// ---Define---
const userNavMB = [
  {
    title: 'For Local Designers',
    name: 'for local-designers',
    sub_nav: [
      {
        title: 'Become a Local Designer',
        url: '/become-a-local-designer',
      },
      {
        title: 'Local Designer Login',
        url: 'https://extranet.designerjourneys.com/access/admin/',
      },
    ],
  },
  {
    title: 'For Travel Agents',
    name: 'for travel-agents',
    sub_nav: [
      {
        title: 'Become a Travel Agent',
        url: '/sign-up-travel-agent',
      },
      {
        title: 'Travel Agent Login',
        url: useAppConfig().publicDomainAgent,
      },
    ],
  },
  {
    title: 'Logout',
    name: 'logout',
    url: '/',
  },
]
const designBlockClass = 'bg-blue-300 flex items-center justify-center flex-column wp-50 br-8 h-80 fw-700 fs-14 lh-18'
const howItWorks = {
  title: 'How It Works',
  name: 'how-it-works',
  url: '/how-it-works',
}

const route = useRoute()
const router = useRouter()
const store = useStore()
const subNavOpen = ref('')
const domain = publicDomain()
// const is_verified = getCookie('is_verified=')
const is_verified = useGetCookie('is_verified')
const dj_xxer_hash = useGetCookie('dj_xxer_hash',365)
const dj_uid = useGetCookie("dj_uid", 365)
const firstName = ref('')
const lastName = ref('')
const avatar = ref(null)
const getUserName = ref('')
const tokenChat = ref('')

// ---Computed
const showEnquiryButton = computed(() => {
  const domain = publicDomain()
  const routeNameTV = ['enquiry-confirmation','local-designer','trip-plan-bookable','trip-gallery','bookings-listing','booking-details','enquiries-listing','account-settings','search-result','login-traveller','signup-traveller','signup-agent','forgot-password','how-it-works','about-us','contact-us','become-local-designer','private-group-tours','sign-up-travel-agent','terms-of-use','privacy-policy','data-protection','cookie-declaration','traveller-protections']
  return (((domain === 'traveller') && (route.name === 'home')) || ((domain === 'traveller') && (route.name === 'trip-plan'))  || ((domain === 'traveller') && (routeNameTV.indexOf(route.name) !== -1)))
})
const tabOpen = computed(() => store.state.generalStore.generalData.megaMenuInforMB.statusSubNav)
const menuItemSelected = computed(() => store.state.generalStore.generalData.megaMenuInforMB.itemSelected)
const topMenu = computed(() => store.state.generalStore.topMenuData)
const userInfo = computed(() => store.getters['authStore/getUserInfo'])

const designURL = () => {
  // if (domain === 'agent') return { name: 'gallery-enquiry-steps' }
  if (route.name === 'types-gallery' && route.params?.types_) {
    // return { name: 'gallery-enquiry-steps', params: { galleryTypes: route.params.types, steps: 'where' } }
    return {
      name: 'enquiry-submit-destination',
      params: { galleryTypes: route.params?.types_ },
    }
  }
  if ((route.name === 'gallery-local-designers' && route.params.country_) || (route.name === 'country-travel-guide' && route.params.country)) {
    // if(process.env.VUE_APP_ENQUIRY_SINGLE) return { name: 'enquiry-submit-destination', params: { galleryTypes: route.params.country } }
    return {
      name: 'enquiry-submit-destination',
      params: { galleryTypes: route.params.country },
    }
  }
  // return { name: 'enquiry-steps', params: { steps: 'where' } }
  return { name: 'enquiry-submit-global' }
}

// ---Watch---
watch(
  () => route.name,
  () => {
    store.commit('generalStore/setTabIndex', 0)
    const data = {
      status: false,
      itemSelected: '',
    }
    store.commit('generalStore/setMegaMenuStatusMB', data)
  },
)
watch(
  () => props.userData,
  (data) => {
    userInfo.value = data
    firstName.value = userInfo.value?.first_name
    lastName.value = userInfo.value?.last_name
    avatar.value = userInfo.value?.avatar
    if (firstName.value && lastName.value) {
      getUserName.value = firstName.value.charAt(0).toUpperCase() + lastName.value.charAt(0).toUpperCase()
    }
    // console.log('MegaMenuMobile watch: ',userInfo.value,' == ', firstName.value,' == ',lastName.value)
  },
)

// ---Method---
const handleLogout = async () => {
  // console.log(route.name);
  // const COOKIE_DURATION = 0
  await store.dispatch('authStore/logout')
  // setCookie('is_verified', 0, COOKIE_DURATION)
  // setCookie('dj_xxer_hash', 0, COOKIE_DURATION)
  is_verified.value = null
  dj_xxer_hash.value = null
  dj_uid.value = null
  localStorage.removeItem('chat_array')
  sessionStorage.removeItem('auth_path')
  if (pages.includes(route.name)) {
    window.location.href = '/'
  } else {
    window.location.reload()
  }
}

const redirectToBooking = () => {
  closeMegaMenu()
  router.push({ name: 'bookings-listing' })
}
const closeMegaMenu = () => {
  const data = {
    status: false,
    statusSubNav: false,
    itemSelected: '',
  }
  store.commit('generalStore/setMegaMenuStatusMB', data)
}
const toDesignTrip = () => {
  closeMegaMenu()
  router.push(designURL())
}
const toLogin = () => {
  closeMegaMenu()
  if (domain === 'agent') {
    router.push({ name: 'home' })
  } else {
    router.push({ name: 'login-traveller' })
  }
}
const toSignUp = () => {
  closeMegaMenu()
  if (domain === 'agent') {
    router.push({ name: 'signup-agent' })
  } else {
    router.push({ name: 'signup-traveller' })
  }
}
const toAccountSettings = () => {
  closeMegaMenu()
  router.push({ name: 'account-settings' })
}
const toEnquiries = () => {
  closeMegaMenu()
  router.push({ name: 'enquiries-listing' })
}
const getNavHeight = (item) => {
  const element = document.getElementById(`sub-nav-mb-${item.name}`)
  let height = element.offsetHeight
  return `${height}px`
}
const handleActive = (e) => {
  console.log(e)
}
const handleSubNav = (item) => {
  if (item.sub_nav) {
    if (!menuItemSelected.value || menuItemSelected.value === `sub-nav-mb-${item.name}`) {
      const element = document.getElementById(`sub-nav-mb-${item.name}`)
      element.classList.toggle('active')

      const data = {
        status: true,
        statusSubNav: element,
        itemSelected: `sub-nav-mb-${item.name}`,
      }
      store.commit('generalStore/setMegaMenuStatusMB', data)
    } else {
      document.getElementById(menuItemSelected.value).classList.remove('active')
      const element = document.getElementById(`sub-nav-mb-${item.name}`).classList.toggle('active')
      const data = {
        status: true,
        statusSubNav: element,
        itemSelected: `sub-nav-mb-${item.name}`,
      }
      store.commit('generalStore/setMegaMenuStatusMB', data)
    }
  } else {
    store.commit('generalStore/setTabIndex', 0)
    const data = {
      status: false,
      statusSubNav: false,
      itemSelected: '',
    }
    store.commit('generalStore/setMegaMenuStatusMB', data)
    if (item.url !== '') {
      router.push({ path: item.url })
    } else {
      window.open(item.externalURL, '_blank')
    }
  }
}

const userLogin = async () => {
  // console.log('MegaMenuMobile onMounted: ',userInfo.value)
  if (is_verified.value || userInfo.value.user_id) {
    // const checkHash = getCookie('dj_xxer_hash=')
    try {
      await store.dispatch('authStore/verify')
      if(userInfo.value.status === 200) {
        firstName.value = userInfo.value.firstName
        lastName.value = userInfo.value.lastName
        avatar.value = userInfo.value.avatar
        getUserName.value = firstName.value.charAt(0).toUpperCase() + lastName.value.charAt(0).toUpperCase() || ''
        tokenChat.value = userInfo.value.token
        let sdkToken = tokenChat.value
        sdkToken && await TfChat.init(sdkToken)
        if (!dj_xxer_hash.value) {
          // setCookie('dj_xxer_hash', userInfo.value.hash, COOKIE_DURATION)
          dj_xxer_hash.value = userInfo.value.hash
        }
      } else {
        await store.dispatch('authStore/logout')
        is_verified.value = null
        dj_xxer_hash.value = null
        dj_uid.value = null
        window.location.reload()
        localStorage.removeItem('chat_array')
        sessionStorage.removeItem('auth_path')
      }
    } catch (err) {
      if ((err?.status || err?.response?.status) === 401) {
        // const COOKIE_DURATION = 0
        await store.dispatch('authStore/logout')
        // setCookie('is_verified', 0, COOKIE_DURATION)
        // setCookie('dj_xxer_hash', 0, COOKIE_DURATION)
        is_verified.value = null
        dj_xxer_hash.value = null
        dj_uid.value = null
        window.location.reload()
        localStorage.removeItem('chat_array')
        sessionStorage.removeItem('auth_path')
      }
    }
  }
}

// ---Mounted---
onMounted(async ()=>{
  await nextTick(async()=> await userLogin())
})
</script>

<style lang="scss" scoped>
#mega-menu-mb {
  background-color: #f8fbff;
  height: 100%;
  overflow-y: auto;
  scrollbar-color: transparent $blue-branding;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    // width: 14px;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: $blue-branding;
    border-radius: 100px;
  }
  .circle {
    @extend %circle;
  }
  .user {
    .item {
      flex: 1 1 0%;
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0;
        max-width: 33%;
      }
      button {
        height: 100%;
        padding-right: 22px;
        padding-left: 22px;
      }
    }
  }
  // @media screen and (max-width: 330px)  {
  //   .user {
  //     .item {
  //       flex: 1 1 0%;
  //       margin-right: 3px;

  //       &:last-of-type {
  //         margin-right: 0;
  //       }
  //       button {
  //         padding-right: 0;
  //         padding-left: 0;
  //       }
  //     }
  //   }
  // }
}

.parent-nav-mb {
  /*-webkit-transition: height .8s ease-in-out;
  transition: height .8s ease-in-out;*/
  .sub-tab {
    transition: opacity, transform 0.3s ease-in-out;
    opacity: 0;
    height: 0;
    visibility: hidden;
    display: none;
  }
  .icon-up {
    display: none;
  }
  &.active {
    .sub-tab {
      opacity: 1;
      transform: translateX(0);
      height: auto;
      visibility: visible;
      display: block;
    }
    .icon-down {
      display: none;
    }
    .icon-up {
      display: block;
    }
  }
}
/*.button-blue {
  background: rgba(164, 203, 250, 0.3);
}
.button-blue-dark {
  background: #A5CAFA;
  color: black;
}*/
</style>
