<template>
  <div class="right-navigation">
    <ul>
      <li v-for="(item, index) in topMenu" :key="`header-navigation-${index}`">
        <TabsDT :list='item' :classTitle='itemClass'></TabsDT>
      </li>
      <li>
        <a :href="blogURL" target='_blank' :class='itemClass'><span>Blog</span></a>
      </li>
      <li v-for="(item, index) in navigation" :key="`header-navigation-${index}`">
<!--        <template v-if='item.sub_nav'>
          <TabsDT :list='item' :classTitle='itemClass'></TabsDT>
        </template>
        <template v-else>-->
          <router-link :to="item.url" @click='openURL()' :class='itemClass'>
            <span v-if='item.svg' class="mr-10" v-html="item.svg"></span>
            <span>{{ item.title }}</span>
          </router-link>
<!--        </template>-->
      </li>
    </ul>
  </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import TabsDT from '../MegaMenu/items/TabsDT'

export default {
  name: 'NavigationDefault',
  components: { TabsDT },
  props: {
    navigation: { type: [Object, Array] },
    headerType: {
      type: String, default: 'Traveller' // Traveller, Agent, DesignAgent
    },
  },
  data() {
    return {
      itemClass: 'lh-18 flex items-center item-parent fw-700'
    }
  },
  setup() {
    const store = useStore()

    const topMenu = computed(() => store.state.generalStore.topMenuData)

    const openURL = () => {
      store.commit('generalStore/setTabIndex', 0)
      const data = {
        status: false,
        itemSelected: {}
      }
      store.commit('generalStore/setMegaMenuStatus', data)
      // router.push(item.url)
    }

    const blogURL = computed(() => {
      return `${useRuntimeConfig().public.publicDomain}/blog/`
    })

    return { openURL, topMenu, blogURL }
  }
}
</script>

<style lang='scss'>
  @import './NavigationStyle.scss';
</style>
